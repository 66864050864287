* {
  box-sizing: border-box;
}

.MuiIconButton-root {
  color: inherit !important;
}

.MuiTab-wrapper {
  flex-direction: row !important;
}

.MuiTab-wrapper > svg {
  margin-bottom: 0 !important;
  margin-right: 6px;
}

.MuiTab-root {
  font-size: inherit !important;
}

.MuiTab-labelIcon {
  min-height: 0 !important;
}

.MuiInputBase-root {
  font-size: inherit !important;
  color: inherit !important;
}

html, body, #root, .App {
  height: 100%;
}

/* Disable Android Chrome font size boost */
body * {
  max-height: 100000px;
}

.App {
  display: flex;
  flex-direction: column;
}

.App > * {
  flex-shrink: 0;
}

#header {
  font-size: 1.2rem;
  background-color: #00a030;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

#body {
  display: flex;
  flex-direction: column;
}

#body > * {
  flex-shrink: 0;
}

.toolbar {
  display: flex;
  align-items: center;
}

.toolbar > * {
  height: 100%;
}

.listContent > * {
  cursor: pointer;
  padding: 0 8px;
  border-bottom: solid 1px #00000020;
  height: 32px;
  line-height: 32px;
}

.medicineName {
  background-color: #808080;
  padding: 0 8px;
}

.medicineAttributes {
  padding: 0 8px;
}

#root .fill {
  flex-grow: 1;
  flex-shrink: 1;
  overflow: auto;
}

.attributeName {
  font-weight: bold;
}

.medicineAttributes > div:not(:first-child) {
  margin-top: 1rem;
}

.attributeValue {
  margin-bottom: 8px;
}

.fullscreen {
  position: absolute;
  width: 100%;
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .App { color: #eee; background: #333; }
  .listContent > * { border-bottom: solid 1px #eeeeee20; }
}
